import React from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "context/AuthContext";

type Props = {};

const Index = (props: Props) => {
    const { user } = useUser();
    const navigate = useNavigate();

    if (user === null) {
        return <>loading</>;
    }

    if (user.user_id && user.user_role === "admin") {
        navigate("dashboard");
    } else {
        navigate("login");
    }

    return <></>;
};

export default Index;
