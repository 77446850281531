import React from "react";

import MainLayout from "components/Layout/MainLayout";
import ProfileCard from "components/ProfileCard";

type Props = {};

const Index = (props: Props) => {
    return (
        <div className="bg-gray-100">
            <MainLayout accountHeader={true}>
                <ProfileCard />
            </MainLayout>
        </div>
    );
};

export default Index;
