import React, { useState, createContext, useEffect, useContext } from "react";

interface IUserContext {
    user: any | null;
    setUser: (id: number | string | null, role?: string, token?: string) => void;
}

const defaultState = {
    user: null,
    setUser: (any) => {},
};

const UserContext = createContext<IUserContext>(defaultState);

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [userState, setUserState] = useState<{ user_role: string; user_id: string | number; token?: string } | null>(null);

    useEffect(() => {
        if (window.localStorage) {
            const user_info = window.localStorage.getItem("brainspace-user") || "";
            let user: { id: string | number; role: string; token: string | undefined } | null = null;

            if (user_info) {
                user = JSON.parse(user_info);
            }

            if (user !== null) {
                setUser(user.id, user.role, user.token);
            } else {
                setUserState({ user_role: "", user_id: "", token: "" });
            }
        }
    }, []);

    const setUser = (id: string | number | null, role: string = "user", token?: string) => {
        let user_token: string | undefined = undefined;
        if (id === null) {
            window.localStorage.removeItem("brainspace-user");
            setUserState({ user_role: "", user_id: "", token: "" });
            return;
        }
        if (role === "admin") {
            user_token = token;
        }
        if (id) {
            window.localStorage.setItem("brainspace-user", JSON.stringify({ id: id.toString(), role: role, token: user_token }));
            setUserState({ user_id: id, user_role: role, token: user_token });
        }
    };

    return <UserContext.Provider value={{ user: userState, setUser: setUser }}>{children}</UserContext.Provider>;
};
