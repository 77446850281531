import React from "react";

import { Link } from "react-router-dom";

type Props = {
    children?: React.ReactNode;
    bgImage: string;
    title: string;
};

const FormLayout = ({ children, bgImage, title }: Props) => {
    return (
        <div className="flex min-h-screen h-full w-full flex-col md:flex-row">
            {/* left part -- bg image */}
            <div className="flex-1 py-16 px-8 relative flex flex-col justify-between h-screen overflow-auto">
                {/* bg image */}
                <div className="absolute top-0 bottom-0 left-0 right-0 -z-10 forms-bg-gradient">
                    <img src={bgImage} alt="onboarding" className="h-full w-full object-cover opacity-[0.06]" />
                </div>
                {/* logo */}
                <Link to={"/"} className="w-fit">
                    <img src="/Brainspace-logo.png" alt="brainspace logo" height={70} width={250} className="object-contain" />
                </Link>
                {/* bottom text */}
                <div className="text-white tracking-wide">
                    <h1 className="text-2xl sm:text-3xl lg:text-5xl my-4">Casting Calls & Auditions online</h1>
                    <p className="my-4 lg:text-lg">
                        We help ordinary people get extraordinary acting, modeling, and entertainment jobs.
                        <br />
                        No experience required.
                    </p>
                    <p className="text-white text-5xl ml-auto w-fit block">›››››››››››››››</p>
                </div>
            </div>
            {/* right part -- form */}
            <div className="flex-1 p-4 md:p-8 h-screen overflow-auto">
                <div className="border-b border-primary-pink-50 py-2 mb-8">
                    <h1 className="text-3xl text-primary-pink-50 mb-2">{title}</h1>
                    <p className="mb-2 text-gray-400 tracking-wide">Kindly fill out the form below</p>
                </div>
                {children}
            </div>
        </div>
    );
};

export default FormLayout;
