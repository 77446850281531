import React from "react";

import { useUser } from "context/AuthContext";

import { Link } from "react-router-dom";
import Dropdown from "components/UI/Dropdown";

type Props = {
    accountHeader?: boolean;
};

const Header = ({ accountHeader }: Props) => {
    const { user } = useUser();

    return (
        <header className={`absolute top-0 left-0 right-0 z-50 ${user?.user_id ? "bg-white shadow" : ""}`}>
            <div className="container  m-auto py-2 flex items-center justify-between h-20">
                <nav className="flex items-center">
                    {/* logo */}
                    <Link to="/">
                        <img src="/Brainspace-Logo-colored.webp" alt="brainspace logo" className="h-full object-contain" />
                    </Link>
                    {/*  */}
                    {user?.user_id ? (
                        <ul className="flex items-center mx-4">
                            <li className="mx-2">
                                <Link to="/casting-calls" className="ml-auto text-gray-800">
                                    Casting Calls
                                </Link>
                            </li>
                            <li className="mx-2">
                                <Link to="/find-talent" className="ml-auto text-gray-800">
                                    Find Talent
                                </Link>
                            </li>
                        </ul>
                    ) : (
                        <></>
                    )}
                </nav>
                {/*  */}
                {user?.user_id ? (
                    <Dropdown
                        title={<div className="h-10 w-10 bg-gray-200 rounded-full"></div>}
                        links={[
                            { text: "My profile", path: "/account" },
                            { text: "Dashboard", path: "/account/dashboard" },
                            { text: "Logout", path: "/account/logout" },
                        ]}
                    />
                ) : (
                    <>
                        {/* <Link to="/login" className="ml-auto text-gray-800">
                        Login
                    </Link> */}
                    </>
                )}
            </div>
            {accountHeader ? (
                <div className="bg-gray-100 border-t border-b py-3">
                    <nav className="container mx-auto ">
                        <ul className="flex items-center text-gray-400 text-sm">
                            <li className="mx-4">
                                <Link to="/casting-calls" className="px-4">
                                    All
                                </Link>
                            </li>
                            <li className="mx-4">
                                <Link to="/casting-calls/matching" className="px-4">
                                    Matching (0)
                                </Link>
                            </li>
                            <li className="mx-4">
                                <Link to="/casting-calls/my-applications" className="px-4">
                                    My Applications (0)
                                </Link>
                            </li>
                            <li className="mx-4">
                                <Link to="/casting-calls/saved" className="px-4">
                                    Saved (0)
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            ) : (
                <></>
            )}
        </header>
    );
};

export default Header;
