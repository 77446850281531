import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Toaster } from "react-hot-toast";

import { UserProvider } from "context/AuthContext";

import Home from "pages/home";

// forms
import Register from "pages/register";
import Login from "pages/login";
import Step1 from "pages/forms/step1";
import Step2 from "pages/forms/step2";
import Step3 from "pages/forms/step3";
import Step4 from "pages/forms/step4";
import Step5 from "pages/forms/step5";

// admin
import Admin from "pages/admin/index";
import AdminDashboard from "pages/admin/dashboard";
import AdminLogin from "pages/admin/login";

// user
import UserDashboard from "pages/account/dashboard";
import User from "pages/account/index";
import Logout from "pages/account/logout";

import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <UserProvider>
            <Toaster />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />

                    {/* form  */}
                    <Route path="register" element={<Register />} />
                    {/* <Route path="login" element={<Login />} /> */}
                    <Route path="register/step1" element={<Step1 />} />
                    <Route path="register/step2" element={<Step2 />} />
                    <Route path="register/step3" element={<Step3 />} />
                    <Route path="register/step4" element={<Step4 />} />
                    <Route path="register/step5" element={<Step5 />} />

                    {/* admin */}
                    <Route path="admin" element={<Admin />} />
                    <Route path="admin/login" element={<AdminLogin />} />
                    <Route path="admin/dashboard" element={<AdminDashboard />} />

                    {/* user */}
                    <Route path="account" element={<User />} />
                    <Route path="account/dashboard" element={<UserDashboard />} />
                    <Route path="account/logout" element={<Logout />} />
                </Routes>
            </BrowserRouter>
        </UserProvider>
    </React.StrictMode>,
);
