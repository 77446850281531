import React from "react";

import Header from "components/shared/Header";

type Props = {
    children?: React.ReactNode;
    accountHeader?: boolean;
    className?: string;
};

const MainLayout = ({ children, accountHeader, className }: Props) => {
    return (
        <div className="h-screen min-h-[500px]">
            <Header />
            <main className="h-full pt-20 relative overflow-hidden">{children}</main>
        </div>
    );
};

export default MainLayout;
