import React from "react";
import { Link } from "react-router-dom";

type Props = {
    children: React.ReactNode;
    href?: string;
    onClick?: () => void;
    color?: string;
    size?: "sm" | "md" | "lg" | "xl";
    rounded?: boolean;
    className?: string;
    type?: "button" | "submit";
    disabled?: boolean;
};

const Button = ({ children, href, onClick, color = "pink", size = "md", rounded = false, type, className, disabled = false }: Props) => {
    return (
        <>
            {href ? (
                <Link
                    to={href}
                    className={` block w-fit border
                    text-${size}
                    ${size === "md" ? "py-3 px-4" : size === "lg" ? "py-3 px-6 font-medium" : ""}
                    ${rounded ? "rounded-full" : "rounded-md"} 
                    ${color === "pink" ? "bg-primary-pink-100  text-slate-50 hover:bg-primary-pink-50" : ""}
                    transition-all
                    ${className}`}
                >
                    {children}
                </Link>
            ) : (
                <button
                    onClick={() => onClick && onClick()}
                    type={type}
                    className={`block w-fit border 
                    text-${size}
                    ${size === "md" ? "py-3 px-4" : size === "lg" ? "py-3 px-6 font-medium" : ""}
                    ${rounded ? "rounded-full" : "rounded-md"} 
                    ${color === "pink" ? "bg-primary-pink-100  text-slate-50 hover:bg-primary-pink-50" : ""}
                    ${disabled ? "cursor-not-allowed bg-opacity-60 pointer-events-none" : "cursor-pointer bg-opacity-100"}
                    transition-all
                   ${className}`}
                    disabled={disabled}
                >
                    {children}
                </button>
            )}
        </>
    );
};

export default Button;
