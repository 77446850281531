import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "utils/axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";

import { useUser } from "context/AuthContext";
import { MEDIA_URL } from "utils/constants";

type Props = {};

const Dashboard = (props: Props) => {
    const [openFormInfo, setOpenFormInfo] = useState<any>(null);
    const [allUsers, setAllUsers] = useState<any[]>([]);

    const userInfoRef = useRef<HTMLDivElement>(null);

    const { user, setUser } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        toast.dismiss();
        if (user !== null) {
            const postData = async () => {
                return await axios({
                    url: "get_casting_users",
                    method: "POST",
                    data: {
                        adminid: user.user_id,
                        token: user.token,
                    },
                });
            };

            const callFunction = postData();
            toast.promise(callFunction, {
                loading: "Fetching forms...",
                success: (res) => {
                    if (res.data.length <= 0) {
                        throw new Error("Something went wrong!");
                    }
                    if (res.data === "token error") {
                        throw new Error(res.data);
                    }

                    setAllUsers(res.data);
                    return `Success!`;
                },
                error: (err) => {
                    let err_msg = (err as Error).message;
                    console.error(err);
                    setUser(null);
                    navigate("/admin/login");
                    return `${err_msg}`;
                },
            });
        }
    }, [navigate, user, setUser]);

    const handleFormDetails = (user_id: number, index: number) => {
        toast.dismiss();

        const postData = async () => {
            return await axios({
                url: "get_casting_user_details",
                method: "POST",
                data: {
                    adminid: user.user_id,
                    token: user.token,
                    userid: user_id,
                },
            });
        };

        const callFunction = postData();
        toast.promise(callFunction, {
            loading: "Fetching form details...",
            success: (res) => {
                if (res.data.length <= 0) {
                    throw new Error("Something went wrong!");
                }
                if (res.data === "token error") {
                    throw new Error(res.data);
                }

                setOpenFormInfo({ ...res.data, ...allUsers[index] });
                return `Success!`;
            },
            error: (err) => {
                let err_msg = (err as Error).message;
                console.error(err);
                // setUser(null);
                // navigate("/admin/login");
                return `${err_msg}`;
            },
        });
    };

    if (user === null) {
        return <>loading</>;
    }

    if (!user.user_id || user.user_role !== "admin") {
        navigate("/admin/login", { replace: true });
    }

    const printUserInfo = (userInfoDiv): void => {
        let divContents = userInfoDiv.innerHTML;

        let a = window.open("", "", "height=500, width=500")!;
        a.document.write("<html>");
        a.document.write("<body > <h1>Div contents are <br>");
        a.document.write(divContents);
        a.document.write("</body></html>");
        a.document.close();
        a.print();
    };

    return (
        <>
            {/* modal */}
            {openFormInfo !== null && (
                <div className="fixed top-0 bottom-0 left-0 right-0 z-10 flex fade ">
                    {/* backdrop */}
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 -z-10" onClick={() => setOpenFormInfo(null)}></div>
                    {/* content */}
                    <div
                        ref={userInfoRef}
                        className="relative bg-white rounded-md shadow w-full max-w-5xl max-h-[95%] m-auto px-8 py-4 overflow-auto"
                    >
                        <button className="absolute right-4 top-4" onClick={() => setOpenFormInfo(null)}>
                            ✕
                        </button>
                        <h3 className="text-xl mt-6 text-primary-pink-50">Personal Details & Appearance</h3>
                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">First Name</h5>
                                <p>{openFormInfo?.first_name}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Last Name</h5>
                                <p>{openFormInfo?.last_name}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">E-mail</h5>
                                <p>
                                    <a className="text-blue-800" href={`mailto:${openFormInfo?.email}`} target="_blank" rel="noreferrer">
                                        {openFormInfo?.email}
                                    </a>
                                </p>
                            </div>
                        </div>

                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Gender</h5>
                                <p>{openFormInfo[0][0].gender}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Nationality</h5>
                                <p>{openFormInfo[0][0].nationality}</p>
                            </div>
                        </div>

                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Date of birth</h5>
                                <p>{dayjs(openFormInfo[0][0].date_of_birth).format("DD-MMM-YYYY")}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Height</h5>
                                <p>{openFormInfo[0][0].height} CM</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Weight</h5>
                                <p>{openFormInfo[0][0].weight} KG</p>
                            </div>
                        </div>

                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Hair Color</h5>
                                <p>{openFormInfo[0][0].hair_color}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Hair Type</h5>
                                <p>{openFormInfo[0][0].hair_type}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Hair Length</h5>
                                <p>{openFormInfo[0][0].hair_length}</p>
                            </div>
                        </div>

                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Eyes Color</h5>
                                <p>{openFormInfo[0][0].eyes_color}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Ethnicity</h5>
                                <p>{openFormInfo[0][0].ethnicity}</p>
                            </div>
                        </div>

                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Shoe Size</h5>
                                <p>{openFormInfo[0][0].shoe_size}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Chest Size</h5>
                                <p>{openFormInfo[0][0].chest_size || "---"} CM</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Waist Size</h5>
                                <p>{openFormInfo[0][0].waist_size || "---"} CM</p>
                            </div>
                        </div>

                        {/* step 2 */}
                        <hr className="my-6" />
                        <h3 className="text-xl text-primary-pink-50">Location & Language</h3>
                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Country</h5>
                                <p>{openFormInfo[0][0].country}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">City</h5>
                                <p>{openFormInfo[0][0].city}</p>
                            </div>
                        </div>

                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Languages</h5>
                                <p>{openFormInfo[0][0].languages}</p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Dialects</h5>
                                <p>{openFormInfo[0][0].dialects || "---"}</p>
                            </div>
                        </div>

                        {/* step 3 */}
                        <hr className="my-6" />
                        <h3 className="text-xl text-primary-pink-50">Experience Details</h3>
                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Experience</h5>
                                <p>{openFormInfo[0][0].experience || "---"}</p>
                            </div>
                        </div>

                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">About User</h5>
                                <p>{openFormInfo[0][0].about_yourself || "---"}</p>
                            </div>
                        </div>

                        {/* step 4 */}
                        <hr className="my-6" />
                        <h3 className="text-xl text-primary-pink-50">Contact Details</h3>
                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Phone Number</h5>
                                <p>
                                    <a className="text-blue-800" href={`tel:${openFormInfo[0][0].phone_number}`} target="_blank" rel="noreferrer">
                                        {openFormInfo[0][0].phone_number || "---"}
                                    </a>
                                </p>
                            </div>
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Whatsapp Number</h5>
                                <p>
                                    {openFormInfo[0][0].whatsapp_number ? (
                                        <a
                                            className="text-blue-800"
                                            href={`tel:${openFormInfo[0][0].whatsapp_number}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {openFormInfo[0][0].whatsapp_number}
                                        </a>
                                    ) : (
                                        "---"
                                    )}
                                </p>
                            </div>
                        </div>

                        {/* step 5 */}
                        <hr className="my-6" />
                        <h3 className="text-xl text-primary-pink-50">Portfolio</h3>
                        {/* row */}
                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Social media accounts</h5>
                                <div>
                                    {openFormInfo[1].length > 0
                                        ? openFormInfo[1].map((social, index) => (
                                              <div key={social.account + index}>
                                                  <span>{social.platform}</span>:{" "}
                                                  <a className="text-blue-800" href={"//" + social.account} target="_blank" rel="noreferrer">
                                                      {social.account}
                                                  </a>
                                              </div>
                                          ))
                                        : "---"}
                                </div>
                            </div>
                        </div>

                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Photos</h5>
                                <div className="flex gap-4 flex-wrap">
                                    {JSON.parse(openFormInfo[0][0].images) && JSON.parse(openFormInfo[0][0].images).length > 0
                                        ? JSON.parse(openFormInfo[0][0].images).map((photo, index) => (
                                              <a
                                                  key={photo + index}
                                                  className="text-blue-800 h-20 w-20 shadow-sm"
                                                  href={MEDIA_URL + photo}
                                                  target="_blank"
                                                  rel="noreferrer"
                                              >
                                                  <img src={MEDIA_URL + photo} alt={photo} className="object-cover h-full w-full whitespace-nowrap" />
                                              </a>
                                          ))
                                        : "---"}
                                </div>
                            </div>
                        </div>

                        <div className="flex space-x-8 justify-between my-2">
                            <div className="flex-1">
                                <h5 className="font-semibold text-lg">Videos</h5>
                                <div className="flex gap-4 flex-wrap">
                                    {JSON.parse(openFormInfo[0][0].videos) && JSON.parse(openFormInfo[0][0].videos) > 0
                                        ? JSON.parse(openFormInfo[0][0].videos).map((video, index) => (
                                              <div key={video + index} className="h-52 w-52 shadow-sm">
                                                  <video src={MEDIA_URL + video} controls></video>
                                              </div>
                                          ))
                                        : "---"}
                                </div>
                            </div>
                        </div>

                        <button className="ml-auto w-fit block" onClick={() => printUserInfo(userInfoRef.current)}>
                            Download info
                        </button>
                    </div>
                </div>
            )}

            <div className="container m-auto ">
                {/* logo */}
                <div className="flex justify-between items-center">
                    <Link to="/">
                        <img src="/Brainspace-Logo-colored.png" alt="brainspace logo" height={80} width={290} />
                    </Link>
                    <button
                        onClick={() => {
                            navigate("/admin/login");
                            setUser(null);
                        }}
                    >
                        Logout
                    </button>
                </div>

                <div className="overflow-x-auto py-4">
                    <table className="min-w-full max-w-full whitespace-nowrap">
                        <thead className="bg-white border-b w-full">
                            <tr>
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                    #
                                </th>
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                    First Name
                                </th>
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                    Last Name
                                </th>
                                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                    E-mail
                                </th>
                            </tr>
                        </thead>
                        <tbody className="w-full">
                            {allUsers.map((user, index) => (
                                <tr
                                    tabIndex={0}
                                    className="bg-gray-100 border-b cursor-pointer"
                                    key={user.user_id + index}
                                    onClick={() => handleFormDetails(user.user_id, index)}
                                >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{index + 1}</td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.first_name}</td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.last_name}</td>
                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{user.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
