import React from "react";
import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

import { useUser } from "context/AuthContext";

import axios from "utils/axios";
import toast from "react-hot-toast";

import Button from "../../components/UI/Button";

type Props = {};

const Login = (props: Props) => {
    const navigate = useNavigate();
    const { setUser } = useUser();

    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({});

    const onSubmitHandler = (data) => {
        const postData = async () => {
            return await axios({
                url: "check_login_casting_admin",
                method: "POST",
                data: data,
            });
        };

        const callFunction = postData();
        toast.promise(callFunction, {
            loading: "Sending your request...",
            success: (res) => {
                if (res.data.length <= 0) {
                    throw new Error("Something wen wrong please try again!");
                }
                setUser(res.data[0].admin_id, "admin", res.data[0].admin_login_token);
                navigate("/admin/dashboard");
                reset();
                return `Logged in successfully!`;
            },
            error: (err) => {
                let err_msg = (err as Error).message;
                console.error(err);
                return `${err_msg}`;
            },
        });
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="container m-auto max-w-sm">
                {/* image */}
                <div className="mb-4">
                    <img src="/Brainspace-Logo-colored.png" alt="brainspace logo" />
                </div>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="">
                        <label className="mb-2 block text-gray-800">
                            Username <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="username"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <input
                                        type="text"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        className="steps-input"
                                        placeholder="Username"
                                    />
                                );
                            }}
                            rules={{ required: "Username is required" }}
                        />
                        <p className="input-error">{errors.username?.message}</p>
                    </div>

                    <div className="">
                        <label className="mb-2 block text-gray-800">
                            Password <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <input
                                        type="password"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        className="steps-input"
                                        placeholder="********"
                                    />
                                );
                            }}
                            rules={{ required: "Password is required" }}
                        />
                        <p className="input-error">{errors.password?.message}</p>
                    </div>

                    <Button type="submit">Login</Button>
                </form>
            </div>
        </div>
    );
};

export default Login;
