import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "context/AuthContext";

type Props = {};

const MainLayout = (props: Props) => {
    const navigate = useNavigate();
    const { user, setUser } = useUser();

    useEffect(() => {
        setUser(null);
        navigate("/");
    }, [navigate, setUser, user]);

    return <div></div>;
};

export default MainLayout;
