import React from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "context/AuthContext";

import axios from "utils/axios";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import toast from "react-hot-toast";
import DatePicker from "react-date-picker";
import dayjs from "dayjs";

import { nationalities } from "utils/constants";

import FormLayout from "components/Layout/FormLayout";
import Button from "components/UI/Button";

type Props = {};

const hairColors = ["Grey", "Blonde", "Brown", "Copper", "Red", "Dark Brown", "Black", "Gray", "Other"];
const hairType = ["Bald", "Straight", "Curly", "Afro", "Wavyy", "Other"];
const hairLength = ["Bald", "Very Short", "Short", "Shoulder Length", "Long", "Very Long", "Other"];
const eyeColors = ["Blue", "Brown", "Black", "Gray", "Green", "Hazel", "Other"];
const ethnicities = ["Middle eastern", "Asian", "Latino", "Filipino", "Indian", "Caucasian", "African", "Other", "Coloured"];
const shoeSize = [
    "Baby",
    "15 EU",
    "16 EU",
    "17 EU",
    "18 EU",
    "19 EU",
    "20 EU",
    "21 EU",
    "22 EU",
    "23 EU",
    "24 EU",
    "25 EU",
    "26 EU",
    "27 EU",
    "28 EU",
    "29 EU",
    "30 EU",
    "31 EU",
    "32 EU",
    "33 EU",
    "34 EU",
    "35 EU",
    "36 EU",
    "37 EU",
    "38 EU",
    "39 EU",
    "40 EU",
    "41 EU",
    "42 EU",
    "43 EU",
    "44 EU",
    "45 EU",
    "46 EU",
    "47 EU",
    "48 EU",
    "49 EU",
    "50 EU",
    "Other",
];

const Step1 = (props: Props) => {
    const navigate = useNavigate();
    const { user } = useUser();

    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({});

    const onSubmitHandler = async (data) => {
        const fd = new FormData();
        data["date_of_birth"] = dayjs(data["date_of_birth"]).format("DD-MMM-YYYY");

        Object.keys(data).forEach((item) => {
            let tmp_data;
            if (data[item]) {
                if (data[item].value) {
                    tmp_data = data[item].value ? data[item].value : data[item];
                } else {
                    tmp_data = data[item];
                }
            } else tmp_data = "";

            fd.append(item, tmp_data);
        });

        fd.append("user_id", user.user_id);

        const postData = async () => {
            return await axios({
                url: "register_casting_user_step1",
                method: "POST",
                data: fd,
            });
        };

        const callFunction = postData();
        toast.promise(callFunction, {
            loading: "Sending your request...",
            success: (res) => {
                reset();
                navigate("/register/step2");
                return `Step 1 completed successfully!`;
            },
            error: (err) => {
                let err_msg = (err as Error).message;
                console.error(err);
                return `${err_msg}`;
            },
        });
    };

    return (
        <FormLayout bgImage="/onboarding.jpg" title="Personal Details & Appearance">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label htmlFor="gender" className="mb-2 block text-gray-800">
                            Gender <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="gender"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        id="gender"
                                        placeholder="Select your gender"
                                        options={[
                                            { value: "male", label: "Male" },
                                            { value: "female", label: "Female" },
                                        ]}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Gender is required" }}
                        />
                        <p className="input-error">{errors.gender?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label htmlFor="nationality" className="mb-2 block text-gray-800">
                            Nationality <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="nationality"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        id="nationality"
                                        placeholder="Select your nationality"
                                        options={nationalities.map((nat) => ({ value: nat, label: nat }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Nationality is required" }}
                        />
                        <p className="input-error">{errors.nationality?.message}</p>
                    </div>
                </div>

                {/* row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Date of birth <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="date_of_birth"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <DatePicker onChange={onChange} value={value} onCalendarClose={onBlur} clearIcon={null} calendarIcon={null} />
                                    // <DatePicker
                                    //     placeholderText="01/01/2000"
                                    //     selected={value}
                                    //     customInput={<input className="steps-input" />}
                                    //     onChange={onChange}
                                    //     onBlur={onBlur}
                                    // />
                                );
                            }}
                            rules={{ required: "Date of birth is required" }}
                        />
                        <p className="input-error">{errors.date_of_birth?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label htmlFor="height" className="mb-2 block text-gray-800">
                            Height <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="height"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <div className="relative">
                                        <input
                                            type="number"
                                            id="height"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className="steps-input"
                                            placeholder="100"
                                        />
                                        <label
                                            htmlFor="height"
                                            className="text-gray-600 absolute top-0 bottom-0 right-0 flex items-center my-2 px-2 border-l"
                                        >
                                            CM
                                        </label>
                                    </div>
                                );
                            }}
                            rules={{ required: "Height is required" }}
                        />
                        <p className="input-error">{errors.height?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label htmlFor="weight" className="mb-2 block text-gray-800">
                            Weight <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="weight"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <div className="relative">
                                        <input
                                            type="number"
                                            id="weight"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className="steps-input"
                                            placeholder="75"
                                        />
                                        <label
                                            htmlFor="weight"
                                            className="text-gray-600 absolute top-0 bottom-0 right-0 flex items-center my-2 px-2 border-l"
                                        >
                                            KG
                                        </label>
                                    </div>
                                );
                            }}
                            rules={{ required: "Weight is required" }}
                        />
                        <p className="input-error">{errors.weight?.message}</p>
                    </div>
                </div>

                {/* Row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Hair color <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="hair_color"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your Hair Color"
                                        options={hairColors.map((hair) => ({ value: hair, label: hair }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Hair color is required" }}
                        />
                        <p className="input-error">{errors.hair_color?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Hair Type <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="hair_type"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your hair type"
                                        options={hairType.map((hair) => ({ value: hair, label: hair }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Hair type is required" }}
                        />
                        <p className="input-error">{errors.hair_type?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Hair Length <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="hair_length"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your hair length"
                                        options={hairLength.map((hair) => ({ value: hair, label: hair }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Hair length is required" }}
                        />
                        <p className="input-error">{errors.hair_length?.message}</p>
                    </div>
                </div>

                {/* Row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Eyes color <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="eyes_color"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your eyes Color"
                                        options={eyeColors.map((eye) => ({ value: eye, label: eye }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Eyes color is required" }}
                        />
                        <p className="input-error">{errors["eyes_color"]?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Ethnicity <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="ethnicity"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your ethnicity"
                                        options={ethnicities.map((eth) => ({ value: eth, label: eth }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Ethnicity is required" }}
                        />
                        <p className="input-error">{errors["ethnicity"]?.message}</p>
                    </div>
                </div>

                {/* row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Shoe size <span className="text-red-600">*</span>
                        </label>
                        <Controller
                            control={control}
                            name="shoe_size"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your shoe size"
                                        options={shoeSize.map((shoe) => ({ value: shoe, label: shoe }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Show size is required" }}
                        />
                        <p className="input-error">{errors["shoe_size"]?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label htmlFor="chest-size" className="mb-2 block text-gray-800">
                            Chest size
                        </label>
                        <Controller
                            control={control}
                            name="chest_size"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <div className="relative">
                                        <input
                                            type="number"
                                            id="chest-size"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className="steps-input"
                                            placeholder="100"
                                        />
                                        <label
                                            htmlFor="chest-size"
                                            className="text-gray-600 absolute top-0 bottom-0 right-0 flex items-center my-2 px-2 border-l"
                                        >
                                            CM
                                        </label>
                                    </div>
                                );
                            }}
                        />
                        <p className="input-error">{errors["chest_size"]?.message}</p>
                    </div>

                    <div className="flex-1">
                        <label htmlFor="waist-size" className="mb-2 block text-gray-800">
                            Waist size
                        </label>
                        <Controller
                            control={control}
                            name="waist_size"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <div className="relative">
                                        <input
                                            type="number"
                                            id="waist-size"
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            className="steps-input"
                                            placeholder="100"
                                        />
                                        <label
                                            htmlFor="waist-size"
                                            className="text-gray-600 absolute top-0 bottom-0 right-0 flex items-center my-2 px-2 border-l"
                                        >
                                            CM
                                        </label>
                                    </div>
                                );
                            }}
                        />
                        <p className="input-error">{errors["waist_size"]?.message}</p>
                    </div>
                </div>

                <Button type="submit">Proceed</Button>
            </form>
        </FormLayout>
    );
};

export default Step1;
