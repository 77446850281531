import React from "react";

import MainLayout from "components/Layout/MainLayout";
import Button from "components/UI/Button";

type Props = {};

const Home = (props: Props) => {
	//force redeploy
    return (
        <>
            <MainLayout className="h-screen min-h-[500px]">
                <div className="container mx-auto h-full flex flex-col mt-20 md:mt-0 justify-center md:justify-start md:flex-row md:items-center ">
                    {/* text part */}
                    <div className="flex-1 h-fit max-w-md text-gray-700">
                        <h1 className="text-2xl sm:text-3xl lg:text-5xl mb-4">Casting Calls & Auditions online</h1>
                        <p className="text-md sm:text-lg mb-4">
                            We help ordinary people get extraordinary acting, modeling, and entertainment jobs. <br /> No experience required.
                        </p>
                        <Button href="register" size="lg">
                            JOIN US NOW
                        </Button>
                    </div>
                    {/* background image */}
                    <div className="relative h-[500px] md:h-full flex items-center md:flex-1 -z-10">
                        <img
                            src="/home-casting.svg"
                            alt="home casting illustration"
                            className="min-h-[500px] min-w-[500px] absolute left-0 right-0 object-contain lg:translate-x-40"
                            // translate-x-20 lg:translate-x-40 xl:translate-x-96
                        />
                    </div>
                </div>
            </MainLayout>
        </>
    );
};

export default Home;
