import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "context/AuthContext";

import axios from "utils/axios";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";

import FormLayout from "components/Layout/FormLayout";
import Button from "components/UI/Button";
import { MEDIA_URL } from "utils/constants";

type Props = {};

const socialMediaPlatforms = ["Vimeo", "IMDB", "Youtube", "Instagram", "Facebook", "TikTok", "Twitter", "LinkedIn", "Personal website", "Other"];

function getRandomInt(min: number = 0, max: number = 99999999): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

const Step5 = (props: Props) => {
    const [socialAccounts, setSocialAccounts] = useState<number[]>([getRandomInt()]);
    const [uploadedFiles, setUploadedFiles] = useState<{ photos: string[]; videos: string[] }>({
        photos: [],
        videos: [],
    });

    const navigate = useNavigate();
    const { user, setUser } = useUser();

    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({});

    const onSubmitHandler = async (data) => {
        let postedData = {};

        const socials: { platform: string; account: string }[] = [];

        Object.keys(data).forEach((item) => {
            if (item.startsWith("account")) {
                let tmp_id = item.split("account")[1];
                let platform = Object.keys(data).find((k) => {
                    if (k.startsWith("platform")) {
                        return k.split("platform")[1] === tmp_id;
                    } else return false;
                });
                if (platform && data[platform]?.value && data[item]) {
                    socials.push({ platform: data[platform]?.value, account: data[item] });
                }
            }
        });
        postedData["socials"] = socials;
        postedData["images"] = uploadedFiles.photos;
        postedData["videos"] = uploadedFiles.videos;

        postedData["user_id"] = user.user_id;
        const postData = async () => {
            return await axios({
                url: "register_casting_user_step5",
                method: "POST",
                data: postedData,
            });
        };

        const callFunction = postData();
        toast.promise(
            callFunction,
            {
                loading: "Sending your request...",
                success: (res) => {
                    reset();
                    navigate("/");
                    setUser(null);
                    return (
                        <button className="relative text-left" onClick={() => toast.dismiss()}>
                            All steps completed successfully! Thank you for your time.
                            <span className="absolute -top-2 -right-3">✕</span>
                        </button>
                    );
                },
                error: (err) => {
                    let err_msg = (err as Error).message;
                    console.error(err);
                    return `${err_msg}`;
                },
            },
            {
                success: {
                    duration: 999999,
                },
            },
        );
    };

    const handleAccountAdd = (): void => {
        let tmp_socialAccounts: number[] = JSON.parse(JSON.stringify(socialAccounts));

        let tmp_random = getRandomInt();
        while (tmp_socialAccounts.indexOf(tmp_random) !== -1) {
            tmp_random = getRandomInt();
        }

        tmp_socialAccounts.push(tmp_random);

        setSocialAccounts(() => tmp_socialAccounts);
    };

    const handleAccountRemove = (id): void => {
        let tmp_socialAccounts: number[] = JSON.parse(JSON.stringify(socialAccounts));
        tmp_socialAccounts = tmp_socialAccounts.filter((acc) => acc !== id);

        setSocialAccounts(() => tmp_socialAccounts);
    };

    const handleFilesUpload = (e: React.ChangeEvent<HTMLInputElement>, type: "videos" | "photos") => {
        const files = e.target.files || [];
        if (files.length > 0) {
            const filesData = new FormData();

            for (const file of files) {
                filesData.append("file", file);
            }
            const postData = async () => {
                return await axios({
                    url: "upload_casting_file",
                    method: "POST",
                    data: filesData,
                });
            };

            const callFunction = postData();
            toast.promise(callFunction, {
                loading: "Uploading file...",
                success: (res) => {
                    if (type === "photos") {
                        setUploadedFiles((p) => ({ photos: [...p.photos, res.data], videos: [...p.videos] }));
                    } else if (type === "videos") {
                        setUploadedFiles((p) => ({ photos: [...p.photos], videos: [...p.videos, res.data] }));
                    }
                    return `File  uploaded`;
                },
                error: (err) => {
                    let err_msg = (err as Error).message;
                    console.error(err);
                    return `${err_msg}`;
                },
            });
        }
    };

    return (
        <FormLayout bgImage="/onboarding.jpg" title="Portfolio">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800 text-lg">
                            Social media accounts:
                            <span className="text-red-600"> *</span>
                        </label>
                        {socialAccounts.map((account, index) => {
                            return (
                                <div key={account + index} className={`mb-2 ${index > 0 ? "mt-8" : ""}`}>
                                    <div className="flex flex-wrap sm:space-x-4 relative">
                                        <Controller
                                            control={control}
                                            name={`platform${account.toString()}`}
                                            render={({ field: { onChange, onBlur, value } }) => {
                                                return (
                                                    <Select
                                                        classNamePrefix=""
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        placeholder="Select a platform"
                                                        options={socialMediaPlatforms.map((platform) => ({
                                                            value: platform,
                                                            label: platform,
                                                        }))}
                                                        className={`min-w-[100px] flex-[0.4] whitespace-nowrap`}
                                                    />
                                                );
                                            }}
                                            rules={{ required: "Platform is required" }}
                                        />
                                        <div className="flex-1 flex items-center">
                                            <Controller
                                                control={control}
                                                name={`account${account.toString()}`}
                                                render={({ field: { onChange, onBlur, value } }) => {
                                                    return (
                                                        <input
                                                            type="text"
                                                            placeholder="URL"
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            className="steps-input h-full"
                                                        />
                                                    );
                                                }}
                                                rules={{ required: "Account is required" }}
                                            />
                                            {index > 0 && (
                                                <button
                                                    className="text-primary-pink-100 ml-2"
                                                    type="button"
                                                    onClick={() => handleAccountRemove(account)}
                                                >
                                                    ✕
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {(errors[`platform${account.toString()}`] || errors[`account${account.toString()}`]) && (
                                        <div className="flex">
                                            <p className="flex-[0.4] mx-2 input-error">{errors[`platform${account.toString()}`]?.message}</p>
                                            <p className="flex-1 mx-2 input-error">{errors[`account${account.toString()}`]?.message}</p>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        <button type="button" className="text-primary-pink-100 cursor-pointer" onClick={handleAccountAdd}>
                            + Add another account
                        </button>
                    </div>
                </div>

                <hr />

                {/* Row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800 text-lg">
                            Photos <span className="text-red-600"> *</span> <span className="text-xs"> (jpeg, png, pdf, jpg)</span>
                        </label>
                        <div className="flex flex-wrap sm:space-x-4">
                            <Controller
                                control={control}
                                name="photo_files[]"
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return (
                                        <div className="overflow-hidden relative w-64 cursor-pointer">
                                            <button className="relative z-0 bg-primary-pink-100 hover:to-primary-pink-50 rounded-sm text-white font-semibold py-2  px-4 w-full inline-flex items-center">
                                                <svg fill="#fff" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
                                                </svg>
                                                <span className="ml-2 cursor-pointer">Upload Photos</span>
                                            </button>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e);
                                                    handleFilesUpload(e, "photos");
                                                }}
                                                onBlur={onBlur}
                                                className="cursor-pointer absolute top-0 bottom-0 left-0 right-0 block py-2 px-4 w-full opacity-0"
                                                type="file"
                                                accept="application/pdf, image/*"
                                            />
                                        </div>
                                    );
                                }}
                                // rules={{ required: "Photos is a required field" }}
                            />
                        </div>
                        <p className="input-error">{errors.photo_files?.message}</p>
                    </div>
                </div>
                <div>
                    {uploadedFiles.photos.length > 0 ? (
                        <div className="flex flex-wrap space-x-6 mb-4">
                            {uploadedFiles.photos.map((photo, index) => (
                                <div key={photo + index} className="h-20 w-20 shadow-sm">
                                    <img src={MEDIA_URL + photo} alt={photo} className="object-cover h-full w-full whitespace-nowrap" />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <hr />

                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800 text-lg">
                            Videos <span className="text-xs">(mp4, webm, ogg, ogv)</span>
                        </label>
                        <div className="flex flex-wrap sm:space-x-4">
                            <Controller
                                control={control}
                                name="video_files[]"
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return (
                                        <div className="overflow-hidden relative w-64 cursor-pointer">
                                            <button className="relative z-0 bg-primary-pink-100 hover:to-primary-pink-50 rounded-sm text-white font-semibold py-2  px-4 w-full inline-flex items-center">
                                                <svg fill="#fff" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
                                                </svg>
                                                <span className="ml-2 cursor-pointer">Upload Videos</span>
                                            </button>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e);
                                                    handleFilesUpload(e, "videos");
                                                }}
                                                onBlur={onBlur}
                                                className="cursor-pointer absolute top-0 bottom-0 left-0 right-0 block py-2 px-4 w-full opacity-0"
                                                type="file"
                                                accept="video/*"
                                            />
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {uploadedFiles.videos.length > 0 ? (
                        <div className="flex flex-wrap space-x-6 mb-4">
                            {uploadedFiles.videos.map((video, index) => (
                                <div key={video + index} className="h-52 w-52 shadow-sm">
                                    <video src={MEDIA_URL + video} controls></video>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                <hr className="mb-4" />

                <Button type="submit">Proceed</Button>
            </form>
        </FormLayout>
    );
};

export default Step5;
