import React from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "context/AuthContext";

import axios from "utils/axios";

import { useForm, Controller } from "react-hook-form";

import toast from "react-hot-toast";

import FormLayout from "components/Layout/FormLayout";
import Button from "components/UI/Button";

const previous_experiences = [
    "Film Crew",
    "Makeup & Hair",
    "Fashion stylists",
    "Photographers",
    "Extras",
    "Influencers",
    "Musicians",
    "Models",
    "Presenters & Emcees",
    "Event Staff and Ushers",
    "Dancer and performers",
    "Actors",
];

type Props = {};

const Step3 = (props: Props) => {
    const navigate = useNavigate();
    const { user } = useUser();

    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({});

    const onSubmitHandler = async (data) => {
        const fd = new FormData();

        const allCheckboxes = Array.from(document.querySelectorAll("input[type='checkbox']:checked"));

        const tmp_exp: (string | undefined)[] = [];
        allCheckboxes.forEach((item) => {
            tmp_exp.push(item.attributes.getNamedItem("name")?.value!.toString());
        });
        fd.append("experience", tmp_exp.join(", "));

        Object.keys(data).forEach((item) => {
            let tmp_data;
            if (data[item]) {
                if (data[item].value) {
                    tmp_data = data[item].value ? data[item].value : data[item];
                } else {
                    tmp_data = data[item];
                }
            } else tmp_data = "";

            let tmp_arr: string[] = [];
            if (tmp_data[0].value) {
                tmp_data.forEach((element) => {
                    tmp_arr.push(element.value);
                });
            }

            fd.append(item, tmp_arr.length > 0 ? tmp_arr : tmp_data);
        });

        fd.append("user_id", user.user_id);
        const postData = async () => {
            return await axios({
                url: "register_casting_user_step3",
                method: "POST",
                data: fd,
            });
        };

        const callFunction = postData();
        toast.promise(callFunction, {
            loading: "Sending your request...",
            success: (res) => {
                reset();
                navigate("/register/step4");
                return `Step 3 completed successfully!`;
            },
            error: (err) => {
                let err_msg = (err as Error).message;
                console.error(err);
                return `${err_msg}`;
            },
        });
    };

    return (
        <FormLayout bgImage="/onboarding.jpg" title="Experience Details">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            I have previous experience in:
                            <span className="text-red-600"> *</span>
                        </label>
                        {previous_experiences.map((exp, index) => (
                            <div key={exp + index} className="flex flex-row items-center select-none cursor-pointer relative my-1">
                                <input type="checkbox" id={exp} name={exp} />
                                <label className="mx-2" htmlFor={exp}>
                                    {exp}
                                </label>
                            </div>
                        ))}
                        <p className="input-error">{errors.country?.message}</p>
                    </div>
                </div>

                {/* Row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Tell us about yourself <span className="text-red-600"> *</span>
                            <span className="text-xs"> Include any special skills or things that set you apart</span>
                        </label>
                        <Controller
                            control={control}
                            name="about_yourself"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <textarea value={value} onChange={onChange} onBlur={onBlur} className="steps-input h-40">
                                        {value}
                                    </textarea>
                                );
                            }}
                            rules={{ required: "Field is required" }}
                        />
                        <p className="input-error">{errors["about_yourself"]?.message}</p>
                    </div>
                </div>

                <Button type="submit">Proceed</Button>
            </form>
        </FormLayout>
    );
};

export default Step3;
