import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "context/AuthContext";

import axios from "utils/axios";

import { useForm, Controller } from "react-hook-form";

import Select from "react-select";
import toast from "react-hot-toast";
import "react-datepicker/dist/react-datepicker.css";

import { countries, languages } from "utils/constants";
import { countriesCities } from "utils/countries-cities";

import FormLayout from "components/Layout/FormLayout";
import Button from "components/UI/Button";

type Props = {};

const Step2 = (props: Props) => {
    const [citiesOptions, setCitiesOptions] = useState<string[]>([]);

    const navigate = useNavigate();
    const { user } = useUser();

    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({});

    const onSubmitHandler = async (data) => {
        const fd = new FormData();

        Object.keys(data).forEach((item) => {
            let tmp_data;
            if (data[item]) {
                if (data[item]?.value) {
                    tmp_data = data[item].value ? data[item].value : data[item];
                } else {
                    tmp_data = data[item];
                }
            } else tmp_data = "";

            let tmp_arr: string[] = [];
            if (tmp_data[0]?.value) {
                tmp_data.forEach((element) => {
                    tmp_arr.push(element.value);
                });
            }

            fd.append(item, tmp_arr.length > 0 ? tmp_arr : tmp_data);
        });

        fd.append("user_id", user.user_id);
        const postData = async () => {
            return await axios({
                url: "register_casting_user_step2",
                method: "POST",
                data: fd,
            });
        };

        const callFunction = postData();
        toast.promise(callFunction, {
            loading: "Sending your request...",
            success: (res) => {
                if (res.data === "error") {
                    throw new Error(res.data);
                }
                reset();
                navigate("/register/step3");
                return `Step 2 completed successfully!`;
            },
            error: (err) => {
                let err_msg = (err as Error).message;
                console.error(err);
                return `${err_msg}`;
            },
        });
    };

    return (
        <FormLayout bgImage="/onboarding.jpg" title="Location & Language ">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            I currently live in <span className="text-red-600"> *</span>
                        </label>
                        <Controller
                            control={control}
                            name="country"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={(e) => {
                                            onChange(e);
                                            if (countriesCities[e.value]?.length > 0) {
                                                setCitiesOptions(countriesCities[e.value]);
                                            } else {
                                                setCitiesOptions(["No cities availble"]);
                                            }
                                        }}
                                        onBlur={() => {
                                            onBlur();
                                        }}
                                        value={value}
                                        placeholder="Select your country"
                                        options={countries.map((ctry) => ({ value: ctry.name, label: ctry.name }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "Country is required" }}
                        />
                        <p className="input-error">{errors.country?.message}</p>
                    </div>
                </div>

                {/* row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            In the city of
                            <span className="text-red-600"> *</span>
                        </label>
                        <Controller
                            control={control}
                            name="city"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your city"
                                        options={citiesOptions.map((cit) => ({ value: cit, label: cit }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: "City is required" }}
                        />
                        <p className="input-error">{errors.city?.message}</p>
                    </div>
                </div>

                {/* Row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            I speak these languages
                            <span className="text-red-600"> *</span>
                        </label>
                        <Controller
                            control={control}
                            name="languages"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your languages"
                                        options={languages.map((lang) => ({ value: lang, label: lang }))}
                                        className={`w-full`}
                                        isMulti
                                    />
                                );
                            }}
                            rules={{ required: "Language is required" }}
                        />
                        <p className="input-error">{errors.languages?.message}</p>
                    </div>
                </div>

                {/* Row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            And these dialects <span className="text-xs">Select a language</span>
                        </label>
                        <Controller
                            control={control}
                            name="dialects"
                            render={({ field: { onChange, onBlur, value } }) => {
                                return (
                                    <Select
                                        classNamePrefix="steps-form-select"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        placeholder="Select your dialects"
                                        options={languages.map((lang) => ({ value: lang, label: lang }))}
                                        className={`w-full`}
                                    />
                                );
                            }}
                            rules={{ required: false }}
                        />
                        <p className="input-error">{errors["dialects"]?.message}</p>
                    </div>
                </div>

                <Button type="submit">Proceed</Button>
            </form>
        </FormLayout>
    );
};

export default Step2;
