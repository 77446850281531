import React from "react";

import { Link } from "react-router-dom";

type Props = {
    title: React.ReactNode;
    links: { text: React.ReactNode; path: string }[];
};

const Dropdown = ({ title, links }: Props) => {
    return (
        <div tabIndex={0} className="relative inline-block text-left dropdown-wrapper">
            <button
                type="button"
                className="dropdown-title inline-flex justify-center w-full text-sm font-medium text-gray-700"
                aria-expanded="true"
                aria-haspopup="true"
            >
                {title}
            </button>

            <button
                className="dropdown-body origin-top-right text-left absolute right-0 pt-2 min-w-[150px] rounded-md shadow-lg bg-white"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex={-1}
            >
                <div className="py-1" role="none">
                    {links.map((item, index) => (
                        <Link key={item.path + index} to={item.path} className="text-gray-700 block px-4 py-2" role="menuitem" tabIndex={-1}>
                            {item.text}
                        </Link>
                    ))}
                </div>
            </button>
        </div>
    );
};

export default Dropdown;
