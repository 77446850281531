import React from "react";
import { Link } from "react-router-dom";

import Button from "./UI/Button";

type Props = {};

const ProfileCard = (props: Props) => {
    const profile = {
        name: "nicolas",
        country: "lebanon",
        city: "beirut",
        gender: "male",
        age: "21",
        about: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ullam, tempore aspernatur perspiciatis amet iure repudiandae vero, neque necessitatibus perferendis incidunt exercitationem minima, vel delectus quasi at quae eveniet ab. Animi.",
    };

    return (
        <div className="container mx-auto my-10 ">
            <div className="bg-white border border-gray-200">
                {/* general profile info */}
                <div className="p-6 flex gap-8">
                    {/* image */}
                    <div className="h-72 w-48 flex flex-col">
                        <img src="" alt="profile" className="flex-1" />
                        <Button color="pink" className="text-sm !w-full">
                            Add profile picture
                        </Button>
                    </div>
                    {/* info */}
                    <div className="flex-1">
                        <div className="flex items-center justify-between">
                            <div className="capitalize">
                                <h2 className="text-xl font-bold">{profile.name}</h2>
                                <div className="text-sm">
                                    <span className="text-gray-600">
                                        {profile.country}, {profile.city}
                                    </span>
                                    <span className="text-gray-400 ml-4">
                                        {profile.gender} {profile.age} years old
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <Button className="!text-sm !py-2">Edit profile details</Button>
                                <Button className="!text-sm !py-2">Profile URL</Button>
                            </div>
                        </div>
                        <div className="border-t border-b w-full border-dashed my-4 py-4 flex items-center justify-between">
                            <Link to="/account/connection/recommended">0 Recommended</Link>
                            <Link to="/account/connection/following">0 Following</Link>
                            <Link to="/account/connection/followers">0 Followers</Link>
                            <Link to="/account/connection/views">0 Views</Link>
                            <Link to="/account/connection/casting">0 Casting</Link>
                        </div>

                        <div className="w-fullmy-4">{profile.about}</div>
                    </div>
                </div>
                {/* navigation */}
                <nav></nav>
            </div>
        </div>
    );
};

export default ProfileCard;
