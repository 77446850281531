export const MEDIA_URL = "https://backend.brainspace.sa/";

export const nationalities: string[] = [
    "Saudi",
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
];

export const countries = [
    { name: "Saudi Arabia", code: "SA" },
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
];

export const languages = [
    "Arabic",
    "Abkhaz",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanian",
    "Amharic",
    "Aragonese",
    "Armenian",
    "Assamese",
    "Avaric",
    "Avestan",
    "Aymara",
    "Azerbaijani",
    "Bambara",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bihari",
    "Bislama",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan; Valencian",
    "Chamorro",
    "Chechen",
    "Chichewa; Chewa; Nyanja",
    "Chinese",
    "Chuvash",
    "Cornish",
    "Corsican",
    "Cree",
    "Croatian",
    "Czech",
    "Danish",
    "Divehi; Dhivehi; Maldivian;",
    "Dutch",
    "English",
    "Esperanto",
    "Estonian",
    "Ewe",
    "Faroese",
    "Fijian",
    "Finnish",
    "French",
    "Fula; Fulah; Pulaar; Pular",
    "Galician",
    "Georgian",
    "German",
    "Greek, Modern",
    "Guaraní",
    "Gujarati",
    "Haitian; Haitian Creole",
    "Hausa",
    "Hebrew",
    "Hebrew",
    "Herero",
    "Hindi",
    "Hiri Motu",
    "Hungarian",
    "Interlingua",
    "Indonesian",
    "Interlingue",
    "Irish",
    "Igbo",
    "Inupiaq",
    "Ido",
    "Icelandic",
    "Italian",
    "Inuktitut",
    "Japanese",
    "Javanese",
    "Kalaallisut, Greenlandic",
    "Kannada",
    "Kanuri",
    "Kashmiri",
    "Kazakh",
    "Khmer",
    "Kikuyu, Gikuyu",
    "Kinyarwanda",
    "Kirghiz, Kyrgyz",
    "Komi",
    "Kongo",
    "Korean",
    "Kurdish",
    "Kwanyama, Kuanyama",
    "Latin",
    "Luxembourgish, Letzeburgesch",
    "Luganda",
    "Limburgish, Limburgan, Limburger",
    "Lingala",
    "Lao",
    "Lithuanian",
    "Luba-Katanga",
    "Latvian",
    "Manx",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Māori",
    "Marathi (Marāṭhī)",
    "Marshallese",
    "Mongolian",
    "Nauru",
    "Navajo, Navaho",
    "Norwegian Bokmål",
    "North Ndebele",
    "Nepali",
    "Ndonga",
    "Norwegian Nynorsk",
    "Norwegian",
    "Nuosu",
    "South Ndebele",
    "Occitan",
    "Ojibwe, Ojibwa",
    "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    "Oromo",
    "Oriya",
    "Ossetian, Ossetic",
    "Panjabi, Punjabi",
    "Pāli",
    "Persian",
    "Polish",
    "Pashto, Pushto",
    "Portuguese",
    "Quechua",
    "Romansh",
    "Kirundi",
    "Romanian, Moldavian, Moldovan",
    "Russian",
    "Sanskrit (Saṁskṛta)",
    "Sardinian",
    "Sindhi",
    "Northern Sami",
    "Samoan",
    "Sango",
    "Serbian",
    "Scottish Gaelic; Gaelic",
    "Shona",
    "Sinhala, Sinhalese",
    "Slovak",
    "Slovene",
    "Somali",
    "Southern Sotho",
    "Spanish; Castilian",
    "Sundanese",
    "Swahili",
    "Swati",
    "Swedish",
    "Tamil",
    "Telugu",
    "Tajik",
    "Thai",
    "Tigrinya",
    "Tibetan Standard, Tibetan, Central",
    "Turkmen",
    "Tagalog",
    "Tswana",
    "Tonga (Tonga Islands)",
    "Turkish",
    "Tsonga",
    "Tatar",
    "Twi",
    "Tahitian",
    "Uighur, Uyghur",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Venda",
    "Vietnamese",
    "Volapük",
    "Walloon",
    "Welsh",
    "Wolof",
    "Western Frisian",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zhuang, Chuang",
];

export const countryCodes = [
    {
        name: "Saudi Arabia",
        code: "SA",
        flag: "em-flag-sa",
        dial_code: "+966",
    },
    {
        name: "Afghanistan",
        code: "AF",
        flag: "em-flag-af",
        dial_code: "+93",
    },
    {
        name: "Aland Islands",
        code: "AX",
        flag: "em-flag-ax",
        dial_code: "+358",
    },
    {
        name: "Albania",
        code: "AL",
        flag: "em-flag-al",
        dial_code: "+355",
    },
    {
        name: "Algeria",
        code: "DZ",
        flag: "em-flag-dz",
        dial_code: "+213",
    },
    {
        name: "AmericanSamoa",
        code: "AS",
        flag: "em-flag-as",
        dial_code: "+1684",
    },
    {
        name: "Andorra",
        code: "AD",
        flag: "em-flag-ad",
        dial_code: "+376",
    },
    {
        name: "Angola",
        code: "AO",
        flag: "em-flag-ao",
        dial_code: "+244",
    },
    {
        name: "Anguilla",
        code: "AI",
        flag: "em-flag-ai",
        dial_code: "+1264",
    },
    {
        name: "Antarctica",
        code: "AQ",
        flag: "em-flag-aq",
        dial_code: "+672",
    },
    {
        name: "Antigua and Barbuda",
        code: "AG",
        flag: "em-flag-ag",
        dial_code: "+1268",
    },
    {
        name: "Argentina",
        code: "AR",
        flag: "em-flag-ar",
        dial_code: "+54",
    },
    {
        name: "Armenia",
        code: "AM",
        flag: "em-flag-am",
        dial_code: "+374",
    },
    {
        name: "Aruba",
        code: "AW",
        flag: "em-flag-aw",
        dial_code: "+297",
    },
    {
        name: "Australia",
        code: "AU",
        flag: "em-flag-au",
        dial_code: "+61",
    },
    {
        name: "Austria",
        code: "AT",
        flag: "em-flag-at",
        dial_code: "+43",
    },
    {
        name: "Azerbaijan",
        code: "AZ",
        flag: "em-flag-az",
        dial_code: "+994",
    },
    {
        name: "Bahamas",
        code: "BS",
        flag: "em-flag-bs",
        dial_code: "+1242",
    },
    {
        name: "Bahrain",
        code: "BH",
        flag: "em-flag-bg",
        dial_code: "+973",
    },
    {
        name: "Bangladesh",
        code: "BD",
        flag: "em-flag-bd",
        dial_code: "+880",
    },
    {
        name: "Barbados",
        code: "BB",
        flag: "em-flag-bb",
        dial_code: "+1246",
    },
    {
        name: "Belarus",
        code: "BY",
        flag: "em-flag-by",
        dial_code: "+375",
    },
    {
        name: "Belgium",
        code: "BE",
        flag: "em-flag-be",
        dial_code: "+32",
    },
    {
        name: "Belize",
        code: "BZ",
        flag: "em-flag-bz",
        dial_code: "+501",
    },
    {
        name: "Benin",
        code: "BJ",
        flag: "em-flag-bj",
        dial_code: "+229",
    },
    {
        name: "Bermuda",
        code: "BM",
        flag: "em-flag-bm",
        dial_code: "+1441",
    },
    {
        name: "Bhutan",
        code: "BT",
        flag: "em-flag-bt",
        dial_code: "+975",
    },
    {
        name: "Bolivia, Plurinational State of",
        code: "BO",
        flag: "em-flag-bo",
        dial_code: "+591",
    },
    {
        flag: "em-flag-bq",
    },
    {
        name: "Bosnia and Herzegovina",
        code: "BA",
        flag: "em-flag-ba",
        dial_code: "+387",
    },
    {
        name: "Botswana",
        code: "BW",
        flag: "em-flag-bw",
        dial_code: "+267",
    },
    {
        flag: "em-flag-abv",
    },
    {
        name: "Brazil",
        code: "BR",
        flag: "em-flag-br",
        dial_code: "+55",
    },
    {
        name: "British Indian Ocean Territory",
        code: "IO",
        flag: "em-flag-io",
        dial_code: "+246",
    },
    {
        name: "Brunei Darussalam",
        code: "BN",
        flag: "em-flag-bn",
        dial_code: "+673",
    },
    {
        name: "Bulgaria",
        code: "BG",
        flag: "em-flag-bg",
        dial_code: "+359",
    },
    {
        name: "Burkina Faso",
        code: "BF",
        flag: "em-flag-bf",
        dial_code: "+226",
    },
    {
        name: "Burundi",
        code: "BI",
        flag: "em-flag-bi",
        dial_code: "+257",
    },
    {
        name: "Cambodia",
        code: "KH",
        flag: "em-flag-kh",
        dial_code: "+855",
    },
    {
        name: "Cameroon",
        code: "CM",
        flag: "em-flag-cm",
        dial_code: "+237",
    },
    {
        name: "Canada",
        code: "CA",
        flag: "em-flag-ca",
        dial_code: "+1",
    },
    {
        name: "Cape Verde",
        code: "CV",
        flag: "em-flag-cv",
        dial_code: "+238",
    },
    {
        name: "Cayman Islands",
        code: "KY",
        flag: "em-flag-ky",
        dial_code: "+ 345",
    },
    {
        name: "Central African Republic",
        code: "CF",
        flag: "em-flag-cf",
        dial_code: "+236",
    },
    {
        name: "Chad",
        code: "TD",
        flag: "em-flag-td",
        dial_code: "+235",
    },
    {
        name: "Chile",
        code: "CL",
        flag: "em-flag-cl",
        dial_code: "+56",
    },
    {
        name: "China",
        code: "CN",
        flag: "em-flag-cn",
        dial_code: "+86",
    },
    {
        name: "Christmas Island",
        code: "CX",
        flag: "em-flag-cx",
        dial_code: "+61",
    },
    {
        name: "Cocos (Keeling) Islands",
        code: "CC",
        flag: "em-flag-cc",
        dial_code: "+61",
    },
    {
        name: "Colombia",
        code: "CO",
        flag: "em-flag-co",
        dial_code: "+57",
    },
    {
        name: "Comoros",
        code: "KM",
        flag: "em-flag-km",
        dial_code: "+269",
    },
    {
        name: "Congo",
        code: "CG",
        flag: "em-flag-cg",
        dial_code: "+242",
    },
    {
        name: "Congo, The Democratic Republic of the Congo",
        code: "CD",
        flag: "em-flag-cd",
        dial_code: "+243",
    },
    {
        name: "Cook Islands",
        code: "CK",
        flag: "em-flag-ck",
        dial_code: "+682",
    },
    {
        name: "Costa Rica",
        code: "CR",
        flag: "em-flag-cr",
        dial_code: "+506",
    },
    {
        name: "Cote d'Ivoire",
        code: "CI",
        flag: "em-flag-ci",
        dial_code: "+225",
    },
    {
        name: "Croatia",
        code: "HR",
        flag: "em-flag-hr",
        dial_code: "+385",
    },
    {
        name: "Cuba",
        code: "CU",
        flag: "em-flag-cu",
        dial_code: "+53",
    },
    {
        flag: "em-flag-cw",
    },
    {
        name: "Cyprus",
        code: "CY",
        flag: "em-flag-cy",
        dial_code: "+357",
    },
    {
        name: "Czech Republic",
        code: "CZ",
        flag: "em-flag-cz",
        dial_code: "+420",
    },
    {
        name: "Denmark",
        code: "DK",
        flag: "em-flag-dk",
        dial_code: "+45",
    },
    {
        name: "Djibouti",
        code: "DJ",
        flag: "em-flag-dj",
        dial_code: "+253",
    },
    {
        name: "Dominica",
        code: "DM",
        flag: "em-flag-dm",
        dial_code: "+1767",
    },
    {
        name: "Dominican Republic",
        code: "DO",
        flag: "em-flag-do",
        dial_code: "+1849",
    },
    {
        name: "Ecuador",
        code: "EC",
        flag: "em-flag-ec",
        dial_code: "+593",
    },
    {
        name: "Egypt",
        code: "EG",
        flag: "em-flag-eg",
        dial_code: "+20",
    },
    {
        name: "El Salvador",
        code: "SV",
        flag: "em-flag-sv",
        dial_code: "+503",
    },
    {
        name: "Equatorial Guinea",
        code: "GQ",
        flag: "em-flag-gq",
        dial_code: "+240",
    },
    {
        name: "Eritrea",
        code: "ER",
        flag: "em-flag-er",
        dial_code: "+291",
    },
    {
        name: "Estonia",
        code: "EE",
        flag: "em-flag-ee",
        dial_code: "+372",
    },
    {
        name: "Ethiopia",
        code: "ET",
        flag: "em-flag-et",
        dial_code: "+251",
    },
    {
        name: "Falkland Islands (Malvinas)",
        code: "FK",
        flag: "em-flag-fk",
        dial_code: "+500",
    },
    {
        name: "Faroe Islands",
        code: "FO",
        flag: "em-flag-fo",
        dial_code: "+298",
    },
    {
        name: "Fiji",
        code: "FJ",
        flag: "em-flag-fj",
        dial_code: "+679",
    },
    {
        name: "Finland",
        code: "FI",
        flag: "em-flag-fi",
        dial_code: "+358",
    },
    {
        name: "France",
        code: "FR",
        flag: "em-flag-fr",
        dial_code: "+33",
    },
    {
        name: "French Guiana",
        code: "GF",
        flag: "em-flag-gf",
        dial_code: "+594",
    },
    {
        name: "French Polynesia",
        code: "PF",
        flag: "em-flag-pf",
        dial_code: "+689",
    },
    {
        flag: "em-flag-tf",
    },
    {
        name: "Gabon",
        code: "GA",
        flag: "em-flag-ga",
        dial_code: "+241",
    },
    {
        name: "Gambia",
        code: "GM",
        flag: "em-flag-gm",
        dial_code: "+220",
    },
    {
        name: "Georgia",
        code: "GE",
        flag: "em-flag-ge",
        dial_code: "+995",
    },
    {
        name: "Germany",
        code: "DE",
        flag: "em-flag-de",
        dial_code: "+49",
    },
    {
        name: "Ghana",
        code: "GH",
        flag: "em-flag-gh",
        dial_code: "+233",
    },
    {
        name: "Gibraltar",
        code: "GI",
        flag: "em-flag-gi",
        dial_code: "+350",
    },
    {
        name: "Greece",
        code: "GR",
        flag: "em-flag-gr",
        dial_code: "+30",
    },
    {
        name: "Greenland",
        code: "GL",
        flag: "em-flag-gl",
        dial_code: "+299",
    },
    {
        name: "Grenada",
        code: "GD",
        flag: "em-flag-gd",
        dial_code: "+1473",
    },
    {
        name: "Guadeloupe",
        code: "GP",
        flag: "em-flag-gp",
        dial_code: "+590",
    },
    {
        name: "Guam",
        code: "GU",
        flag: "em-flag-gu",
        dial_code: "+1671",
    },
    {
        name: "Guatemala",
        code: "GT",
        flag: "em-flag-gt",
        dial_code: "+502",
    },
    {
        name: "Guernsey",
        code: "GG",
        flag: "em-flag-gg",
        dial_code: "+44",
    },
    {
        name: "Guinea",
        code: "GN",
        flag: "em-flag-gn",
        dial_code: "+224",
    },
    {
        name: "Guinea-Bissau",
        code: "GW",
        flag: "em-flag-gw",
        dial_code: "+245",
    },
    {
        name: "Guyana",
        code: "GY",
        flag: "em-flag-gy",
        dial_code: "+595",
    },
    {
        name: "Haiti",
        code: "HT",
        flag: "em-flag-ht",
        dial_code: "+509",
    },
    {
        flag: "em-flag-hm",
    },
    {
        name: "Holy See (Vatican City State)",
        code: "VA",
        flag: "em-flag-va",
        dial_code: "+379",
    },
    {
        name: "Honduras",
        code: "HN",
        flag: "em-flag-hn",
        dial_code: "+504",
    },
    {
        name: "Hong Kong",
        code: "HK",
        flag: "em-flag-hk",
        dial_code: "+852",
    },
    {
        name: "Hungary",
        code: "HU",
        flag: "em-flag-hu",
        dial_code: "+36",
    },
    {
        name: "Iceland",
        code: "IS",
        flag: "em-flag-is",
        dial_code: "+354",
    },
    {
        name: "India",
        code: "IN",
        flag: "em-flag-in",
        dial_code: "+91",
    },
    {
        name: "Indonesia",
        code: "ID",
        flag: "em-flag-id",
        dial_code: "+62",
    },
    {
        name: "Iran, Islamic Republic of Persian Gulf",
        code: "IR",
        flag: "em-flag-ir",
        dial_code: "+98",
    },
    {
        name: "Iraq",
        code: "IQ",
        flag: "em-flag-iq",
        dial_code: "+964",
    },
    {
        name: "Ireland",
        code: "IE",
        flag: "em-flag-ie",
        dial_code: "+353",
    },
    {
        name: "Isle of Man",
        code: "IM",
        flag: "em-flag-im",
        dial_code: "+44",
    },
    {
        name: "Italy",
        code: "IT",
        flag: "em-flag-it",
        dial_code: "+39",
    },
    {
        name: "Jamaica",
        code: "JM",
        flag: "em-flag-jm",
        dial_code: "+1876",
    },
    {
        name: "Japan",
        code: "JP",
        flag: "em-flag-jp",
        dial_code: "+81",
    },
    {
        name: "Jersey",
        code: "JE",
        flag: "em-flag-je",
        dial_code: "+44",
    },
    {
        name: "Jordan",
        code: "JO",
        flag: "em-flag-jo",
        dial_code: "+962",
    },
    {
        name: "Kazakhstan",
        code: "KZ",
        flag: "em-flag-kz",
        dial_code: "+77",
    },
    {
        name: "Kenya",
        code: "KE",
        flag: "em-flag-ke",
        dial_code: "+254",
    },
    {
        name: "Kiribati",
        code: "KI",
        flag: "em-flag-ki",
        dial_code: "+686",
    },
    {
        name: "Korea, Democratic People's Republic of Korea",
        code: "KP",
        flag: "em-flag-kp",
        dial_code: "+850",
    },
    {
        name: "Korea, Republic of South Korea",
        code: "KR",
        flag: "em-flag-kr",
        dial_code: "+82",
    },
    {
        name: "Kuwait",
        code: "KW",
        flag: "em-flag-kw",
        dial_code: "+965",
    },
    {
        name: "Kyrgyzstan",
        code: "KG",
        flag: "em-flag-kg",
        dial_code: "+996",
    },
    {
        name: "Laos",
        code: "LA",
        flag: "em-flag-la",
        dial_code: "+856",
    },
    {
        name: "Latvia",
        code: "LV",
        flag: "em-flag-lv",
        dial_code: "+371",
    },
    {
        name: "Lebanon",
        code: "LB",
        flag: "em-flag-lb",
        dial_code: "+961",
    },
    {
        name: "Lesotho",
        code: "LS",
        flag: "em-flag-ls",
        dial_code: "+266",
    },
    {
        name: "Liberia",
        code: "LR",
        flag: "em-flag-lr",
        dial_code: "+231",
    },
    {
        name: "Libyan Arab Jamahiriya",
        code: "LY",
        flag: "em-flag-ly",
        dial_code: "+218",
    },
    {
        name: "Liechtenstein",
        code: "LI",
        flag: "em-flag-li",
        dial_code: "+423",
    },
    {
        name: "Lithuania",
        code: "LT",
        flag: "em-flag-lt",
        dial_code: "+370",
    },
    {
        name: "Luxembourg",
        code: "LU",
        flag: "em-flag-lu",
        dial_code: "+352",
    },
    {
        name: "Macao",
        code: "MO",
        flag: "em-flag-mo",
        dial_code: "+853",
    },
    {
        name: "Macedonia",
        code: "MK",
        flag: "em-flag-mk",
        dial_code: "+389",
    },
    {
        name: "Madagascar",
        code: "MG",
        flag: "em-flag-mg",
        dial_code: "+261",
    },
    {
        name: "Malawi",
        code: "MW",
        flag: "em-flag-mw",
        dial_code: "+265",
    },
    {
        name: "Malaysia",
        code: "MY",
        flag: "em-flag-my",
        dial_code: "+60",
    },
    {
        name: "Maldives",
        code: "MV",
        flag: "em-flag-mv",
        dial_code: "+960",
    },
    {
        name: "Mali",
        code: "ML",
        flag: "em-flag-ml",
        dial_code: "+223",
    },
    {
        name: "Malta",
        code: "MT",
        flag: "em-flag-mt",
        dial_code: "+356",
    },
    {
        name: "Marshall Islands",
        code: "MH",
        flag: "em-flag-mh",
        dial_code: "+692",
    },
    {
        name: "Martinique",
        code: "MQ",
        flag: "em-flag-mq",
        dial_code: "+596",
    },
    {
        name: "Mauritania",
        code: "MR",
        flag: "em-flag-mr",
        dial_code: "+222",
    },
    {
        name: "Mauritius",
        code: "MU",
        flag: "em-flag-mu",
        dial_code: "+230",
    },
    {
        name: "Mayotte",
        code: "YT",
        flag: "em-flag-yt",
        dial_code: "+262",
    },
    {
        name: "Mexico",
        code: "MX",
        flag: "em-flag-mx",
        dial_code: "+52",
    },
    {
        name: "Micronesia, Federated States of Micronesia",
        code: "FM",
        flag: "em-flag-fm",
        dial_code: "+691",
    },
    {
        name: "Moldova",
        code: "MD",
        flag: "em-flag-md",
        dial_code: "+373",
    },
    {
        name: "Monaco",
        code: "MC",
        flag: "em-flag-mc",
        dial_code: "+377",
    },
    {
        name: "Mongolia",
        code: "MN",
        flag: "em-flag-mn",
        dial_code: "+976",
    },
    {
        name: "Montenegro",
        code: "ME",
        flag: "em-flag-me",
        dial_code: "+382",
    },
    {
        name: "Montserrat",
        code: "MS",
        flag: "em-flag-ms",
        dial_code: "+1664",
    },
    {
        name: "Morocco",
        code: "MA",
        flag: "em-flag-ma",
        dial_code: "+212",
    },
    {
        name: "Mozambique",
        code: "MZ",
        flag: "em-flag-mz",
        dial_code: "+258",
    },
    {
        name: "Myanmar",
        code: "MM",
        flag: "em-flag-mm",
        dial_code: "+95",
    },
    {
        name: "Namibia",
        code: "NA",
        flag: "em-flag-na",
        dial_code: "+264",
    },
    {
        name: "Nauru",
        code: "NR",
        flag: "em-flag-nr",
        dial_code: "+674",
    },
    {
        name: "Nepal",
        code: "NP",
        flag: "em-flag-np",
        dial_code: "+977",
    },
    {
        name: "Netherlands",
        code: "NL",
        flag: "em-flag-nl",
        dial_code: "+31",
    },
    {
        name: "New Caledonia",
        code: "NC",
        flag: "em-flag-nc",
        dial_code: "+687",
    },
    {
        name: "New Zealand",
        code: "NZ",
        flag: "em-flag-nz",
        dial_code: "+64",
    },
    {
        name: "Nicaragua",
        code: "NI",
        flag: "em-flag-ni",
        dial_code: "+505",
    },
    {
        name: "Niger",
        code: "NE",
        flag: "em-flag-ne",
        dial_code: "+227",
    },
    {
        name: "Nigeria",
        code: "NG",
        flag: "em-flag-ng",
        dial_code: "+234",
    },
    {
        name: "Niue",
        code: "NU",
        flag: "em-flag-nu",
        dial_code: "+683",
    },
    {
        name: "Norfolk Island",
        code: "NF",
        flag: "em-flag-nf",
        dial_code: "+672",
    },
    {
        name: "Northern Mariana Islands",
        code: "MP",
        flag: "em-flag-mp",
        dial_code: "+1670",
    },
    {
        name: "Norway",
        code: "NO",
        flag: "em-flag-no",
        dial_code: "+47",
    },
    {
        name: "Oman",
        code: "OM",
        flag: "em-flag-om",
        dial_code: "+968",
    },
    {
        name: "Pakistan",
        code: "PK",
        flag: "em-flag-pk",
        dial_code: "+92",
    },
    {
        name: "Palau",
        code: "PW",
        flag: "em-flag-pw",
        dial_code: "+680",
    },
    {
        name: "Palestinian Territory, Occupied",
        code: "PS",
        flag: "em-flag-ps",
        dial_code: "+970",
    },
    {
        name: "Panama",
        code: "PA",
        flag: "em-flag-pa",
        dial_code: "+507",
    },
    {
        name: "Papua New Guinea",
        code: "PG",
        flag: "em-flag-pg",
        dial_code: "+675",
    },
    {
        name: "Paraguay",
        code: "PY",
        flag: "em-flag-py",
        dial_code: "+595",
    },
    {
        name: "Peru",
        code: "PE",
        flag: "em-flag-pe",
        dial_code: "+51",
    },
    {
        name: "Philippines",
        code: "PH",
        flag: "em-flag-ph",
        dial_code: "+63",
    },
    {
        name: "Pitcairn",
        code: "PN",
        flag: "em-flag-pn",
        dial_code: "+872",
    },
    {
        name: "Poland",
        code: "PL",
        flag: "em-flag-pl",
        dial_code: "+48",
    },
    {
        name: "Portugal",
        code: "PT",
        flag: "em-flag-pt",
        dial_code: "+351",
    },
    {
        name: "Puerto Rico",
        code: "PR",
        flag: "em-flag-pr",
        dial_code: "+1939",
    },
    {
        name: "Qatar",
        code: "QA",
        flag: "em-flag-qa",
        dial_code: "+974",
    },
    {
        name: "Reunion",
        code: "RE",
        flag: "em-flag-re",
        dial_code: "+262",
    },
    {
        name: "Romania",
        code: "RO",
        flag: "em-flag-ro",
        dial_code: "+40",
    },
    {
        name: "Russia",
        code: "RU",
        flag: "em-flag-ru",
        dial_code: "+7",
    },
    {
        name: "Rwanda",
        code: "RW",
        flag: "em-flag-rw",
        dial_code: "+250",
    },
    {
        name: "Saint Barthelemy",
        code: "BL",
        flag: "em-flag-bl",
        dial_code: "+590",
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        code: "SH",
        flag: "em-flag-sh",
        dial_code: "+290",
    },
    {
        name: "Saint Kitts and Nevis",
        code: "KN",
        flag: "em-flag-kn",
        dial_code: "+1869",
    },
    {
        name: "Saint Lucia",
        code: "LC",
        flag: "em-flag-lc",
        dial_code: "+1758",
    },
    {
        name: "Saint Martin",
        code: "MF",
        flag: "em-flag-mf",
        dial_code: "+590",
    },
    {
        name: "Saint Pierre and Miquelon",
        code: "PM",
        flag: "em-flag-pm",
        dial_code: "+508",
    },
    {
        name: "Saint Vincent and the Grenadines",
        code: "VC",
        flag: "em-flag-vc",
        dial_code: "+1784",
    },
    {
        name: "Samoa",
        code: "WS",
        flag: "em-flag-ws",
        dial_code: "+685",
    },
    {
        name: "San Marino",
        code: "SM",
        flag: "em-flag-sm",
        dial_code: "+378",
    },
    {
        name: "Sao Tome and Principe",
        code: "ST",
        flag: "em-flag-st",
        dial_code: "+239",
    },
    {
        name: "Senegal",
        code: "SN",
        flag: "em-flag-sn",
        dial_code: "+221",
    },
    {
        name: "Serbia",
        code: "RS",
        flag: "em-flag-rs",
        dial_code: "+381",
    },
    {
        name: "Seychelles",
        code: "SC",
        flag: "em-flag-sc",
        dial_code: "+248",
    },
    {
        name: "Sierra Leone",
        code: "SL",
        flag: "em-flag-sl",
        dial_code: "+232",
    },
    {
        name: "Singapore",
        code: "SG",
        flag: "em-flag-sg",
        dial_code: "+65",
    },
    {
        flag: "em-flag-sx",
    },
    {
        name: "Slovakia",
        code: "SK",
        flag: "em-flag-sk",
        dial_code: "+421",
    },
    {
        name: "Slovenia",
        code: "SI",
        flag: "em-flag-si",
        dial_code: "+386",
    },
    {
        name: "Solomon Islands",
        code: "SB",
        flag: "em-flag-sb",
        dial_code: "+677",
    },
    {
        name: "Somalia",
        code: "SO",
        flag: "em-flag-so",
        dial_code: "+252",
    },
    {
        name: "South Africa",
        code: "ZA",
        flag: "em-flag-za",
        dial_code: "+27",
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        code: "GS",
        flag: "em-flag-gs",
        dial_code: "+500",
    },
    {
        name: "South Sudan",
        code: "SS",
        flag: "em-flag-ss",
        dial_code: "+211",
    },
    {
        name: "Spain",
        code: "ES",
        flag: "em-flag-es",
        dial_code: "+34",
    },
    {
        name: "Sri Lanka",
        code: "LK",
        flag: "em-flag-lk",
        dial_code: "+94",
    },
    {
        name: "Sudan",
        code: "SD",
        flag: "em-flag-sd",
        dial_code: "+249",
    },
    {
        name: "Suriname",
        code: "SR",
        flag: "em-flag-sr",
        dial_code: "+597",
    },
    {
        name: "Svalbard and Jan Mayen",
        code: "SJ",
        flag: "em-flag-sj",
        dial_code: "+47",
    },
    {
        name: "Swaziland",
        code: "SZ",
        flag: "em-flag-sz",
        dial_code: "+268",
    },
    {
        name: "Sweden",
        code: "SE",
        flag: "em-flag-se",
        dial_code: "+46",
    },
    {
        name: "Switzerland",
        code: "CH",
        flag: "em-flag-ch",
        dial_code: "+41",
    },
    {
        name: "Syrian Arab Republic",
        code: "SY",
        flag: "em-flag-sy",
        dial_code: "+963",
    },
    {
        name: "Taiwan",
        code: "TW",
        flag: "em-flag-tw",
        dial_code: "+886",
    },
    {
        name: "Tajikistan",
        code: "TJ",
        flag: "em-flag-tj",
        dial_code: "+992",
    },
    {
        name: "Tanzania, United Republic of Tanzania",
        code: "TZ",
        flag: "em-flag-tz",
        dial_code: "+255",
    },
    {
        name: "Thailand",
        code: "TH",
        flag: "em-flag-th",
        dial_code: "+66",
    },
    {
        name: "Timor-Leste",
        code: "TL",
        flag: "em-flag-tl",
        dial_code: "+670",
    },
    {
        name: "Togo",
        code: "TG",
        flag: "em-flag-tg",
        dial_code: "+228",
    },
    {
        name: "Tokelau",
        code: "TK",
        flag: "em-flag-tk",
        dial_code: "+690",
    },
    {
        name: "Tonga",
        code: "TO",
        flag: "em-flag-to",
        dial_code: "+676",
    },
    {
        name: "Trinidad and Tobago",
        code: "TT",
        flag: "em-flag-tt",
        dial_code: "+1868",
    },
    {
        name: "Tunisia",
        code: "TN",
        flag: "em-flag-tn",
        dial_code: "+216",
    },
    {
        name: "Turkey",
        code: "TR",
        flag: "em-flag-tr",
        dial_code: "+90",
    },
    {
        name: "Turkmenistan",
        code: "TM",
        flag: "em-flag-tm",
        dial_code: "+993",
    },
    {
        name: "Turks and Caicos Islands",
        code: "TC",
        flag: "em-flag-tc",
        dial_code: "+1649",
    },
    {
        name: "Tuvalu",
        code: "TV",
        flag: "em-flag-tv",
        dial_code: "+688",
    },
    {
        name: "Uganda",
        code: "UG",
        flag: "em-flag-ug",
        dial_code: "+256",
    },
    {
        name: "Ukraine",
        code: "UA",
        flag: "em-flag-ua",
        dial_code: "+380",
    },
    {
        name: "United Arab Emirates",
        code: "AE",
        flag: "em-flag-ae",
        dial_code: "+971",
    },
    {
        name: "United Kingdom",
        code: "GB",
        flag: "em-flag-gb",
        dial_code: "+44",
    },
    {
        name: "United States",
        code: "US",
        flag: "em-flag-us",
        dial_code: "+1",
    },
    {
        flag: "em-flag-um",
    },
    {
        name: "Uruguay",
        code: "UY",
        flag: "em-flag-uy",
        dial_code: "+598",
    },
    {
        name: "Uzbekistan",
        code: "UZ",
        flag: "em-flag-uz",
        dial_code: "+998",
    },
    {
        name: "Vanuatu",
        code: "VU",
        flag: "em-flag-vu",
        dial_code: "+678",
    },
    {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        code: "VE",
        flag: "em-flag-ve",
        dial_code: "+58",
    },
    {
        name: "Vietnam",
        code: "VN",
        flag: "em-flag-vn",
        dial_code: "+84",
    },
    {
        name: "Virgin Islands, British",
        code: "VG",
        flag: "em-flag-vg",
        dial_code: "+1284",
    },
    {
        name: "Virgin Islands, U.S.",
        code: "VI",
        flag: "em-flag-vi",
        dial_code: "+1340",
    },
    {
        name: "Wallis and Futuna",
        code: "WF",
        flag: "em-flag-wf",
        dial_code: "+681",
    },
    {
        flag: "em-flag-eh",
    },
    {
        name: "Yemen",
        code: "YE",
        flag: "em-flag-ye",
        dial_code: "+967",
    },
    {
        name: "Zambia",
        code: "ZM",
        flag: "em-flag-zm",
        dial_code: "+260",
    },
    {
        name: "Zimbabwe",
        code: "ZW",
        flag: "em-flag-zw",
        dial_code: "+263",
    },
];
