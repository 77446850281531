import React from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "context/AuthContext";

import axios from "utils/axios";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";

import { countryCodes } from "utils/constants";

import FormLayout from "components/Layout/FormLayout";
import Button from "components/UI/Button";

type Props = {};

const Step4 = (props: Props) => {
    const navigate = useNavigate();
    const { user } = useUser();

    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm({});

    const onSubmitHandler = async (data) => {
        const fd = new FormData();

        fd.append("phone_number", `${data["phone_countrycode"]?.value?.split("--")?.[0]} ${data["phone_number"]}`);
        fd.append("whatsapp_number", `${data["whatsapp_countrycode"]?.value?.split("--")?.[0]} ${data["whatsapp_number"]}`);

        fd.append("user_id", user.user_id);
        const postData = async () => {
            return await axios({
                url: "register_casting_user_step4",
                method: "POST",
                data: fd,
            });
        };

        const callFunction = postData();
        toast.promise(callFunction, {
            loading: "Sending your request...",
            success: (res) => {
                reset();
                navigate("/register/step5");
                return `Step 4 completed successfully!`;
            },
            error: (err) => {
                let err_msg = (err as Error).message;
                console.error(err);
                return `${err_msg}`;
            },
        });
    };

    return (
        <FormLayout bgImage="/onboarding.jpg" title="Contact Details ">
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">
                            Phone number:
                            <span className="text-red-600"> *</span>
                        </label>
                        <div className="flex flex-wrap sm:space-x-4">
                            <Controller
                                control={control}
                                name="phone_countrycode"
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return (
                                        <Select
                                            classNamePrefix=""
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            placeholder="+966"
                                            options={countryCodes.map((ctry) => ({
                                                value: `${ctry.dial_code}--${ctry.name}`,
                                                label: `${ctry.dial_code} ${ctry.name}`,
                                            }))}
                                            className={`min-w-[100px] flex-[0.3]`}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="phone_number"
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return <input type="number" value={value} onChange={onChange} onBlur={onBlur} className="steps-input flex-1" />;
                                }}
                                rules={{ required: "Phone number is required" }}
                            />
                        </div>
                        <p className="input-error">{errors.phone_number?.message}</p>
                    </div>
                </div>

                {/* Row */}
                <div className="flex flex-wrap gap-6 my-4">
                    <div className="flex-1">
                        <label className="mb-2 block text-gray-800">Whatsapp number:</label>
                        <div className="flex flex-wrap sm:space-x-4">
                            <Controller
                                control={control}
                                name="whatsapp_countrycode"
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return (
                                        <Select
                                            classNamePrefix=""
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            placeholder="+966"
                                            options={countryCodes.map((ctry) => ({
                                                value: `${ctry.dial_code}--${ctry.name}`,
                                                label: `${ctry.dial_code} ${ctry.name}`,
                                            }))}
                                            className={`min-w-[100px] flex-[0.3]`}
                                        />
                                    );
                                }}
                            />
                            <Controller
                                control={control}
                                name="whatsapp_number"
                                render={({ field: { onChange, onBlur, value } }) => {
                                    return <input type="number" value={value} onChange={onChange} onBlur={onBlur} className="steps-input flex-1" />;
                                }}
                            />
                        </div>
                        <p className="input-error">{errors.whatsapp_number?.message}</p>
                    </div>
                </div>

                <Button type="submit">Proceed</Button>
            </form>
        </FormLayout>
    );
};

export default Step4;
