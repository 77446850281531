import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useUser } from "context/AuthContext";

import axios from "utils/axios";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import toast from "react-hot-toast";

import Button from "components/UI/Button";
import FormLayout from "components/Layout/FormLayout";

const registerSchema = yup.object().shape({
    first_name: yup.string().required("First name is required !"),
    last_name: yup.string().required("Last name is required!"),
    email: yup.string().email("Please enter a valid email format !").required("Email is required!"),
    // password: yup.string().min(4, "Password must contain at least 4 characters").required("Password is required!"),
});

type Props = {};

const Register = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser } = useUser();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(registerSchema),
    });

    useEffect(() => {
        toast.dismiss();
        // ?confirmation_token=7lDF4crlbdKcLMI&email=nicolas.nasr@biners.dev
        if (user !== null) {
            const searchParams = location.search.split("?")[1]?.split("&");
            const confirmation_token = searchParams?.[0]?.split("=")?.[1];
            const email = searchParams?.[1]?.split("=")?.[1];

            if (confirmation_token && email && !user?.user_id) {
                const postData = async () => {
                    return await axios({
                        url: "check_email_confirmation_token",
                        method: "POST",
                        data: {
                            token: confirmation_token,
                            email: email,
                        },
                    });
                };

                const callFunction = postData();
                toast.promise(callFunction, {
                    loading: "Confirming email...",
                    success: (res) => {
                        if (res.data[0].user_id) {
                            navigate(res.data[0].next_step > 0 ? `/register/step${res.data[0].next_step}` : "/", { replace: true });
                            setUser(res.data[0].user_id.toString());
                            return `Verification complete!`;
                        } else {
                            throw new Error(res.data);
                        }
                    },
                    error: (err) => {
                        let err_msg = (err as Error).message;
                        console.error(err);
                        return `${err_msg}`;
                    },
                });
            } else if (user?.user_id) {
                navigate("/register/step1", { replace: true });
            }
        }
    }, [user, location.search, navigate, setUser]);

    const onSubmitHandler = async (data) => {
        data["password"] = "";
        const postData = async () => {
            return await axios({
                url: "register_casting_users",
                method: "POST",
                data: data,
            });
        };

        const callFunction = postData();
        toast.promise(
            callFunction,
            {
                loading: "Sending your request...",
                success: (res) => {
                    if (res.data === "user already exist") {
                        throw new Error("user already exist! If you have used this email before please check your email for a verification link.");
                    }
                    reset();

                    return (
                        <button className="relative text-left" onClick={() => toast.dismiss()}>
                            Registered Successfully! Please check your email.
                            <span className="absolute -top-2 -right-3">✕</span>
                        </button>
                    );
                },
                error: (err) => {
                    let err_msg = (err as Error).message;
                    console.error(err);
                    return `${err_msg}`;
                },
            },
            {
                success: {
                    duration: 999999,
                },
                error: {
                    duration: 10000,
                },
            },
        );
    };

    return (
        <FormLayout bgImage="/onboarding.jpg" title="Register as a Talent">
            <form onSubmit={handleSubmit(onSubmitHandler)} className="w-full text-gray-900">
                {/* input row */}
                <div className="input-wrapper mb-4">
                    <label htmlFor="first_name">
                        First name <span>*</span>
                    </label>
                    <input {...register("first_name")} type="text" name="first_name" id="first_name" placeholder="First Name" />
                    <p className="input-error">{errors.first_name?.message}</p>
                </div>
                <div className="input-wrapper mb-4">
                    <label htmlFor="last_name">
                        Last name <span>*</span>
                    </label>
                    <input {...register("last_name")} type="text" name="last_name" id="last_name" placeholder="Last Name" />
                    <p className="input-error">{errors.last_name?.message}</p>
                </div>
                <div className="input-wrapper mb-4">
                    <label htmlFor="email">
                        Email <span>*</span>
                    </label>
                    <input {...register("email")} type="text" name="email" id="email" placeholder="Email" />
                    <p className="input-error">{errors.email?.message}</p>
                </div>
                {/* <div className="input-wrapper mb-4">
                    <label htmlFor="password">
                        Password <span>*</span>
                    </label>
                    <input {...register("password")} type="password" name="password" id="password" placeholder="First Name" />
                    <p className="input-error">{errors.password?.message}</p>
                </div> */}

                <Button type="submit" className="w-full mt-8" disabled={Object.keys(errors).length > 0}>
                    Register
                </Button>

                <p className="italic my-4 text-center text-gray-500 text-sm">
                    Brainspace is an online casting platform, that allows you access to thousands of Casting Calls for TV Commercials, Movies,
                    Photoshoots
                </p>
            </form>
        </FormLayout>
    );
};

export default Register;
